import { useMemo } from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { optionTitle } from "../constants/Configs";
import { Question } from "../network/question";

type MyShareProps = {
  question: Question;
};
const MyShare = ({ question }: MyShareProps) => {
  const shareTitle = useMemo(
    () =>
      `Q. ${question.text.replace(/\r|\n/g, "")}\n\n${question.options
        .map(
          (o, oIndex) => `${optionTitle[oIndex]}). ${o.replace(/\r|\n/g, "")}`
        )
        .join(`\n`)}\n\nअभ्यास के लिए यह ऐप डाउनलोड करें.`,
    [question]
  );

  return (
    <>
      <WhatsappShareButton
        className="ms-1 d-inline-block"
        url="https://bit.ly/2Oafa55"
        title={shareTitle}
      >
        <WhatsappIcon size={32} round={true} />
      </WhatsappShareButton>
      <FacebookShareButton
        className="ms-1 d-inline-block"
        url="https://bit.ly/2Oafa55"
        hashtag="#jtet #preExam.in"
      >
        <FacebookIcon size={32} round={true} />
      </FacebookShareButton>
      <TelegramShareButton
        className="ms-1 d-inline-block"
        url="https://bit.ly/2Oafa55"
        title={shareTitle}
      >
        <TelegramIcon size={32} round={true} />
      </TelegramShareButton>
    </>
  );
};

export default MyShare;
