type JumbotronProps = {
  children: React.ReactNode;
  className?: string;
};

const Jumbotron = ({ children, className }: JumbotronProps) => {
  return (
    <div className="py-2 p-4">
      <div className={`pt-4 bg-light rounded-3 ${className || ""}`}>
        {children}
      </div>
    </div>
  );
};

export default Jumbotron;
