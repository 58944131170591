import { useEffect, useState } from "react";
import instance from "./api";
import { getData, setData } from "./storage";
type Subject = { _id: string; subject_name: string };
export async function getSubjects(): Promise<Subject[]> {
  const localSubjects = await getData("subject");
  if (localSubjects !== null) {
    return Promise.resolve(localSubjects);
  }
  return instance.get("/subject").then((resp: any) => {
    setData("subject", resp.data.subjects);
    return resp.data.subjects;
  });
}

export const useGetSubjects = () => {
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getSubjects()
      .then((s) => {
        setSubjects(s);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return {
    subjects,
    loading,
  };
};

// import instance from "./api";
// import { getData, setData } from "./storage";
// export async function getSubjects() {
//   const localSubjects = await getData("subject");
//   if (localSubjects !== null) {
//     return Promise.resolve(localSubjects);
//   }
//   return instance.get("/subject").then(resp => {
//     setData("subject", resp.data.subjects);
//     return resp.data.subjects;
//   });
// }
