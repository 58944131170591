import { useEffect, useState } from "react";
import instance from "./api";
import { getData, setData } from "./storage";

type Chapter = { _id: string; chapter_name: string };

export async function getChapters(subject_id: string): Promise<Chapter[]> {
  const keyName = `chapter-${subject_id}`;
  const localChapters = await getData(keyName);
  if (localChapters !== null) {
    return Promise.resolve(localChapters);
  }
  return instance.get(`/chapter/${subject_id}`).then((resp: any) => {
    setData(keyName, resp.data.chapters);
    return resp.data.chapters;
  });
}

export const useGetChapters = (subjectId: string) => {
  const [chapters, setChapters] = useState<Chapter[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getChapters(subjectId)
      .then((c) => {
        setChapters(c);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [subjectId]);

  return {
    chapters,
    loading,
  };
};
