import { createBrowserRouter } from "react-router-dom";
import { routesPath } from "./constants/Configs";
// @ts-ignore
import Chapter from "./screens/Chapter";
import Home from "./screens/Home";
// @ts-ignore
import Question from "./screens/Question";

// @ts-ignore
import Subject from "./screens/Subject";

const router = createBrowserRouter([
  {
    path: routesPath.home,
    Component: Home,
  },
  {
    path: routesPath.subject,
    Component: Subject,
  },
  {
    path: `${routesPath.subject}/:subjectId${routesPath.chapter}/`,
    Component: Chapter,
  },
  {
    path: `${routesPath.subject}/:subjectId${routesPath.chapter}/:chapterId${routesPath.question}`,
    Component: Question,
  },
]);

// const router = createBrowserRouter(
//   createRoutesFromElements(
//     <Route path={routesPath.home} Component={Home}>
//       <Route path={`${routesPath.subject}`} Component={Subject} />
//       <Route path={`${routesPath.chapter}/:subjectId`} Component={Chapter} />
//       <Route path={`${routesPath.question}/:chapterId`} Component={Question} />
//     </Route>
//   )
// );

export default router;
