import { useEffect, useState } from "react";
import instance from "./api";
import { getData, setData } from "./storage";

export type Question = {
  options: [string, string, string, string];
  _id: string;
  text: string;
  answer: number;
};

export async function getQuestions(chapter_id: string): Promise<Question[]> {
  const key = `question-${chapter_id}`;
  const localQuestions = await getData(key);
  if (localQuestions !== null) {
    return Promise.resolve(localQuestions);
  }

  return instance.get(`/question/${chapter_id}`).then((resp: any) => {
    setData(key, resp.data.questions);
    return resp.data.questions;
  });
}

export const useGetQuestions = (chapterId: string) => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getQuestions(chapterId)
      .then((q) => {
        setQuestions(q);
        setLoading(false);
      })
      .catch((e) => {
        console.log("Error on loading question", e);
        setLoading(false);
      });
  }, [chapterId]);

  return {
    questions,
    loading,
  };
};
