import { useCallback, useState } from "react";
import { Alert, Col, Container, Row } from "reactstrap";
import { optionTitle } from "../constants/Configs";
import { Question } from "../network/question";
import MyShare from "./MyShare";

type AnswerOptionsProps = {
  question: Question;
  indexId: number;
};
const AnswerOptions = ({ question, indexId }: AnswerOptionsProps) => {
  const [btnInfo, setBtnInfo] = useState({
    classes: ["secondary", "secondary", "secondary", "secondary"],
    clicked: false,
  });

  const handleBtnClick = useCallback(
    (index: number) => {
      return () => {
        if (btnInfo.clicked) {
          return;
        }
        const newBtnClass = [
          "secondary",
          "secondary",
          "secondary",
          "secondary",
        ];
        newBtnClass[index] = question.answer === index ? "success" : "danger";
        newBtnClass[question.answer] = "success";
        setBtnInfo({ classes: newBtnClass, clicked: true });
      };
    },
    [question.answer, setBtnInfo, btnInfo]
  );

  return (
    <Container>
      <Row>
        <Col className="align-items-stretch">
          <p>
            {indexId + 1}) {question.text}
          </p>
          {question.options.map((o, oIndex) => (
            <Alert
              className="alert-button "
              onClick={handleBtnClick(oIndex)}
              color={btnInfo.classes[oIndex]}
              key={`${question._id}-${oIndex}`}
            >
              {optionTitle[oIndex]}. {o}
              {btnInfo.classes[oIndex] === "success" && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="48"
                  fill="currentColor"
                  className="bi bi-check"
                  viewBox="0 0 16 16"
                >
                  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                </svg>
              )}
              {btnInfo.classes[oIndex] === "danger" && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="48"
                  fill="currentColor"
                  className="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                </svg>
              )}
            </Alert>
          ))}
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mt-2 float-end">
            <MyShare question={question} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AnswerOptions;
