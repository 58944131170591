import { Link, useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import HeaderTitle from "../components/HeaderTitle";
import Jumbotron from "../components/Jumbotron";
import MyLoader from "../components/MyLoader";
import { routesPath } from "../constants/Configs";
import { useGetChapters } from "../network/chapter";

const Chapter = () => {
  const { subjectId } = useParams();
  const { chapters, loading } = useGetChapters(subjectId || "");
  return (
    <div>
      <HeaderTitle title="Chapters" page="chapters" />
      <Jumbotron>
        <Container>
          <Row className="mt-2">
            {loading ? (
              <Col className="text-center">
                <MyLoader />
              </Col>
            ) : null}
          </Row>
          {chapters.map((chapter) => (
            <Row key={chapter._id} className="mt-2">
              <Col className="text-center">
                <Link
                  to={{
                    pathname: `${routesPath.subject}/${subjectId}${routesPath.chapter}/${chapter._id}${routesPath.question}`,
                  }}
                  className="form-control btn btn-info btn-large"
                >
                  {chapter.chapter_name}
                </Link>
              </Col>
            </Row>
          ))}
        </Container>
      </Jumbotron>
    </div>
  );
};

export default Chapter;
