import { apiURL } from "../constants/Configs";
import axios from "axios";
const instanceOpt = {
  baseURL: apiURL,
  // timeout: 1000,
  // headers: { "X-Custom-Header": "foobar" }
};
const instance = axios.create(instanceOpt);

// instance.interceptors.response.use(
//   function(response) {
//     console.log(`got response on axios: ${response.status}`);
//     return response;
//   },
//   function(error) {
//     if (error.response && error.response.status === 401) {
//       console.log(`Log out`);
//       logOut().catch(err => {
//         console.log(`unable to log out: ${err.message}`);
//       });
//     }
//     // Do something with response error
//     console.log(`[ERROR] in api ${error.message}`);
//     return Promise.reject(error);
//   },
// );

export default instance;
// ========some default methods========
