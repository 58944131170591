import { Col, Row } from "reactstrap";

import { useParams } from "react-router-dom";
import AnswerOptions from "../components/AnswerOptions";
import HeaderTitle from "../components/HeaderTitle";
import Jumbotron from "../components/Jumbotron";
import MyLoader from "../components/MyLoader";
import { useGetQuestions } from "../network/question";

const Question = () => {
  const { chapterId } = useParams();
  const { questions, loading } = useGetQuestions(chapterId || "");
  return (
    <div>
      <HeaderTitle title="Questions" page="questions" />
      <Row className="mt-2">
        {loading ? (
          <Col className="text-center">
            <MyLoader />
          </Col>
        ) : null}
      </Row>
      {questions.map((question, index) => (
        <Jumbotron key={question._id} className="mx-1">
          <AnswerOptions question={question} indexId={index} />
        </Jumbotron>
      ))}
    </div>
  );
};

export default Question;
