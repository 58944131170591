import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import HeaderTitle from "../components/HeaderTitle";
import Jumbotron from "../components/Jumbotron";
import { routesPath } from "../constants/Configs";
const Home = () => {
  return (
    <div>
      <HeaderTitle title="" page="home" />
      <Jumbotron className="mx-1">
        <Container>
          <Row>
            <Col className="text-center">
              <h3>JTET Exam Objective Type Question Practice Sets.</h3>
              <div>
                <p>
                  Start online test with multiple choice questions (MCQ) of
                  Teacher Eligibility Test exams CTET, UPTET, JTET, DSSSB, HTET,
                  Rajasthan TET, & KVS. Attempt this practice sets to score high
                  in TET Exams.
                </p>
              </div>
              <p>
                <Link
                  to={{
                    pathname: `${routesPath.subject}`,
                  }}
                  className="btn btn-info btn-large"
                >
                  Start
                </Link>
              </p>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
    </div>
  );
};

export default Home;
