import { Nav, Navbar, NavbarBrand } from "reactstrap";
import GoBack from "./GoBack";

type HeaderTitleProps = {
  title: string;
  page: "home" | "subjects" | "chapters" | "questions";
};

const HeaderTitle = ({ title, page }: HeaderTitleProps) => {
  return (
    <>
      <Navbar color="secondary" light expand="md">
        <NavbarBrand href="/">
          <img
            alt="logo"
            src="/android-chrome-192x192.png"
            style={{
              height: 40,
              width: 40,
            }}
          />
        </NavbarBrand>
        <Nav>
          <label style={{ color: "white", fontSize: "30px" }}>
            JTET Practice Sets
          </label>
        </Nav>
      </Navbar>
      <div className="py-2 p-4">
        <div>
          <GoBack page={page} />
        </div>
        <div>
          <h1 style={{ width: "100%", textAlign: "center" }}>{title}</h1>
        </div>
      </div>
    </>
  );
};

export default HeaderTitle;
