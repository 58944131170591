import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import HeaderTitle from "../components/HeaderTitle";
import Jumbotron from "../components/Jumbotron";
import MyLoader from "../components/MyLoader";
import { routesPath } from "../constants/Configs";
import { useGetSubjects } from "../network/subject";
const Subject = () => {
  const { subjects, loading } = useGetSubjects();

  return (
    <div>
      <HeaderTitle title="Subjects" page="subjects" />
      <Jumbotron className="mx-1">
        <Container>
          <Row className="mt-2">
            {loading ? (
              <Col className="text-center">
                <MyLoader />
              </Col>
            ) : null}
          </Row>
          {subjects.map((subject) => (
            <Row key={subject._id} className="mt-2">
              <Col className="text-center">
                <Link
                  to={{
                    pathname: `${routesPath.subject}/${subject._id}${routesPath.chapter}`,
                  }}
                  className="form-control btn btn-info btn-large"
                >
                  {subject.subject_name}
                </Link>
              </Col>
            </Row>
          ))}
        </Container>
      </Jumbotron>
    </div>
  );
};

export default Subject;
