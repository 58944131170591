import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { routesPath } from "../constants/Configs";

type GoBackProps = {
  page: "home" | "subjects" | "chapters" | "questions";
};

const GoBack = ({ page }: GoBackProps) => {
  return (
    <>
      <div>
        <Breadcrumb>
          {["subjects", "chapters", "questions"].includes(page) && (
            <BreadcrumbItem>
              <Link to="/" relative="path">
                Home
              </Link>
            </BreadcrumbItem>
          )}
          {["subjects", "chapters", "questions"].includes(page) && (
            <BreadcrumbItem active={page === "subjects"}>
              {page === "subjects" ? (
                "Subjects"
              ) : (
                <Link to={routesPath.subject} relative="path">
                  Subjects
                </Link>
              )}
            </BreadcrumbItem>
          )}
          {["chapters", "questions"].includes(page) && (
            <BreadcrumbItem active={page === "chapters"}>
              {page === "chapters" ? (
                "Chapters"
              ) : (
                <Link to="../../" relative="path">
                  Chapters
                </Link>
              )}
            </BreadcrumbItem>
          )}

          {["questions"].includes(page) && (
            <BreadcrumbItem active>Questions</BreadcrumbItem>
          )}
        </Breadcrumb>
      </div>
    </>
  );
};

export default GoBack;
